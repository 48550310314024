<template>
  <div>
    <div v-if="phrases" class="position-table">
      <md-table v-model="phrases">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          class="cursor-pointer"
        >
          <md-table-cell md-label="Id">
            <span class="text ws-nowrap">{{ item.id }}</span>
          </md-table-cell>
          <md-table-cell md-label="Эмоция">
            <img class="emoji" src="@/assets/img/emoji.jpeg" alt="emoji" />
          </md-table-cell>
          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button class="md-just-icon md-simple md-primary">
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>
              <md-button
                class="md-just-icon md-simple md-primary"
                @click="
                  openModal({
                    modalName: 'DeleteConfirm',
                    modalData: `эмоцию ${item.id}`,
                  })
                "
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <Pagination
        :current-page="pagination.current_page"
        :last-page="pagination.last_page"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";
import { mapMutations } from "vuex";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        current_page: 1,
        last_page: 2,
      },
      phrases: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["openModal"]),
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
.emoji {
  max-height: 100px;
  width: auto;
}
</style>
