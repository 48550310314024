<template>
  <Emoji />
</template>

<script>
import Emoji from "@/components/Tables/Emoji.vue";

export default {
  components: { Emoji },
};
</script>
